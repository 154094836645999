<template>
  <div class="app-container">
    <el-row>
      <div>批量新增</div>
      <el-divider />
    </el-row>
    <el-form ref="form" :model="supplierform" :rules="rules" label-width="75px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :label="$t('page.Style')">
              <el-select v-model="supplierform.style" value-key="id" clearable filterable @change="styleSelectChange">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.Color')">
              <el-select v-model="supplierform.color" clearable filterable multiple>
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.Size')">

              <el-select v-model="supplierform.sizes" clearable filterable>
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="供应商" prop="vendorId">
              <el-select v-model="supplierform.vendorId" :disabled="isDisabled" filterable :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">清空</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="uploadTableDataRef"
      v-loading="TableLoading"
      class="mb-3"
      :row-key="getRowKeys"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa'}"
      height="350px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="100"
        :reserve-selection="true"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
      >
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column
        prop="imgUrl"
        align="center"
        sortable
        label="图片"
        width="160"
      >
        <template slot-scope="scope">
          <img :src="scope.row.imgUrl" alt="暂无图片" style="width: 50px;height: 50px">
        </template>
      </el-table-column>
      <el-table-column
        prop="style"
        label="style"
        align="center"
        sortable
      />
      <el-table-column
        prop="color"
        label="color"
        align="center"
        sortable
      />
      <el-table-column
        prop="size"
        label="size"
        align="center"
        sortable
      />
    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        :page-sizes="[10, 50, 200, 500]"
        @pagination="_getPageByBatchInsert"
      />
    </div>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button type="primary" :loading="Loading" @click="onSubmit">确认</el-button>
    </el-row>
  </div>
</template>
<script>
// queryTerraceList, querySiteList,
import { queryVendorList, getPageByBatchInsert, queryStyleList, querySizeList, queryColorList } from '@/api/scm-api'
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination },
  data() {
    return {
      isDisabled: false,
      isDisabledTemp: true,
      siteCode: '',
      platformName: '',
      supplierform: {
        vendorId: this.$route.query.vendorId
      },
      vendorOptions: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      TableLoading: false,
      editRow: {},
      showRow: false, // 切换显示input
      releaseTaskDialog: false, // 释放任务
      tableDatas: [],
      sidvoList: [],
      Loading: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      rules: {
        vendorId: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        platformName: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        siteCode: [{ required: true, message: this.$t('page.required'), trigger: 'change' }]
      },
      alldatas: [],
      getRowKeys(row) {
        return row.myId
      }
    }
  },
  computed: {
    pages() {
      return { current: this.pager.current, size: this.pager.size }
    },
    queryParameter() {
      const { color, style, vendorId, sizes } = this.supplierform
      return Object.assign({ style, vendorId, sizes }, { color: color ? color.toString() : '' }, this.pages)
    }
  },
  mounted() {
    if (this.$route.query.vendorId) {
      this._getPageByBatchInsert()
    }
    this._queryColorList()
    this._querySizeList()
    this._queryStyleList()
    this._queryVendorList()
  },
  methods: {
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
      this.supplierform.vendorId = this.$route.query.vendorId
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.styleName === value)
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._getPageByBatchInsert(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.supplierform.style = ''
      this.supplierform.color = ''
      this.supplierform.sizes = ''
      this.supplierform.vendorId = ''
      this.queryClick(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    _getPageByBatchInsert(pager) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.TableLoading = true
            pager && Object.assign(this.pager, pager)
            const { datas } = await getPageByBatchInsert(this.queryParameter)
            this.tableDatas = datas.records

            // 值判断一次
            if (this.isDisabledTemp) {
              if (this.$route.query.vendorId && this.tableDatas.length === 0) {
                this.isDisabled = false
              } else {
                this.isDisabled = true
              }
              this.isDisabledTemp = false
            }
            this.pager = datas.pager
          } finally {
            this.TableLoading = false
          }
        }
      })
    },
    //  列表分页方法
    handleSizeChange(val) {
      this.pager.size = val
      this._getPageByBatchInsert(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getPageByBatchInsert(this.queryParameter)
    },

    handleSelectionChange(val) {
      let data = ''
      this.sidvoList = []
      for (let i = 0; i < val.length; i++) {
        data = val[i]
        this.sidvoList.push(data)
      }
    },
    // 批量新增确认保存
    onSubmit() {
      if (this.sidvoList.length >= 0) {
        if (sessionStorage.getItem('indatas')) {
          const andvo = JSON.parse(sessionStorage.getItem('indatas'))
          this.sidvoList.map(e => {
            var index = andvo.findIndex((i) => {
              return e.style === i.style && e.color === i.color && e.size === i.size
            })
            if (index === -1) {
              andvo.push(e)
            }
          })
          // console.log('andvo: ', andvo)

          sessionStorage.setItem('indatas', JSON.stringify(andvo))
        } else {
          sessionStorage.setItem('indatas', JSON.stringify(this.sidvoList))
        }
        this.$router.push({
          path: '../',
          append: true,
          query: { 'id': this.$route.query.id, 'disabled': this.$route.query.disabled, 'currencyType': this.$route.query.currencyType, 'vendorId': this.$route.query.vendorId ? this.$route.query.vendorId : this.supplierform.vendorId }
        })
      }
    }
  }
}
</script>

